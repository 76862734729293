<!--
 * @Description: 车辆用户管理系统 车辆管理 车辆信息 cuCarInfo
 * @Author: linfeng
 * @Github: 
 * @Date: 2021-03-27 11:03:14
 * @LastEditors: zhoucheng
-->
<template>
  <div class='mainBody'>
    <!--上半部查询-->
    <el-row class="topSelectTwoLine">
      <el-form :inline="true"
               :model="searchForm">
        <el-row class="topFirstLine">
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="车牌号">
              <el-input v-model="searchForm.plateNumber"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="车牌颜色">
              <el-select clearable
                         v-model="searchForm.numberPlateColorCode"
                         placeholder="请选择">
                <el-option v-for="(item, index) in colorList"
                           :key="index"
                           :label="item.name"
                           :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="车辆类型">
              <el-select clearable
                         v-model="searchForm.vehicleTypeCode"
                         placeholder="请选择">
                <el-option v-for="(item, index) in typeList"
                           :key="index"
                           :label="item.name"
                           :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="绑定手机">
              <el-input v-model="searchForm.phoneNumber"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="topSecondLine">
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="注册时间">
              <el-date-picker v-model="bindTime"
                              type="daterange"
                              align="right"
                              unlink-panels
                              value-format="yyyy-MM-dd HH:mm:ss"
                              range-separator="-"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期"
                              :default-time="['00:00:00', '23:59:59']"
                              :picker-options="pickerOptions">
              </el-date-picker>
            </el-form-item>
          </el-col>

        </el-row>
      </el-form>
      <el-row class="topButton">
        <el-button type="primary"
                   @click="handleClickSearch">查 询</el-button>
        <el-button type="info"
                   @click="resetForm"
                   style="margin-left:17px">重 置</el-button>

      </el-row>
    </el-row>
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <span>车辆信息</span>
        <!-- <el-button class="tableTitleButton"
                   type="success"
                   @click="handleClickADD">新 增</el-button> -->

      </el-row>
      <el-row class="tableContent">
        <!-- 表格 -->
        <dt-table :tableColumnList="tableColumnList"
                  :data="tableList"
                  :map="listMap"
                  :tableConfig='tableConfig'
                  :paginationConfig='paginationConfig'
                  @getList="getList">
          <template slot="isFirst">
            <!-- <el-table-column type="selection"></el-table-column> -->
          </template>
          <template slot="operating">
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-row>
                  <el-col :span="8">
                    <!--  <el-button @click="handleClick(scope.row)"
                               type="text"
                               size="medium">详情</el-button> -->
                  </el-col>
                  <el-col :span="8">
                    <el-button type="text"
                               size="medium"
                               @click="handleClickUpdate(scope.row)">修改</el-button>
                  </el-col>
                  <!-- <el-col :span="8">
                    <el-button type="text"
                               size="medium">删除</el-button>
                  </el-col> -->
                </el-row>
              </template>
            </el-table-column>

          </template>

        </dt-table>
      </el-row>
    </el-row>

    <!-- 修改弹窗 -->
    <el-row>
      <el-dialog title="车辆信息修改"
                 :visible.sync="dialogFormVisible"
                 append-to-body>
        <el-form :model="form"
                 :rules="rules"
                 ref="form">
          <el-row style="margin-bottom:20px">
            <el-form-item label="车牌号"
                          :label-width="formLabelWidth"
                          prop="plate_number">
              <el-input v-model="form.plateNumber"></el-input>
            </el-form-item>
          </el-row>
          <el-row style="margin-bottom:20px">
            <el-form-item prop="number_plate_color_code"
                          label="车牌颜色"
                          :label-width="formLabelWidth">
              <el-select clearable
                         v-model="form.numberPlateColorCode"
                         placeholder="请选择">
                <el-option v-for="(item, index) in colorList"
                           :key="index"
                           :label="item.name"
                           :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item prop="vehicle_type_code"
                          label="车辆类型"
                          :label-width="formLabelWidth">
              <el-select clearable
                         v-model="form.vehicleTypeCode"
                         placeholder="请选择">
                <el-option v-for="(item, index) in typeList"
                           :key="index"
                           :label="item.name"
                           :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-row>
        </el-form>
        <div slot="footer"
             class="dialog-footer">
          <el-row type="flex"
                  justify="end">
            <el-col :span="3">
              <el-button type="primary"
                         @click="formSubmit">确 定</el-button>
            </el-col>
            <el-col :span="2">
              <el-button type="info"
                         @click="dialogFormVisible = false">取 消</el-button>
            </el-col>
          </el-row>
        </div>
      </el-dialog>
    </el-row>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import dtTable from "@/components/table.vue";
import $commonsJs from '@/common/commonsJs.js'

export default {
  //import引入的组件需要注入到对象中才能使用
  components: { dtTable },
  data () {
    //这里存放数据
    return {
      //表格组件
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      tableConfig: { border: true },
      listMap: {
        pageNum: 1,
        pageSize: 15,
      },
      tableColumnList: [
        {
          prop: "plateNumber",
          label: "车牌号",
        },
        {
          prop: "plateColor",
          label: "车牌颜色",
        },
        {
          prop: "vehicleType",
          label: "车辆类型",
        },
        {
          prop: "bindTime",
          label: "绑定时间",
        },
        {
          prop: "phoneNumber",
          label: "绑定手机",
        },
      ],
      // 表格数据
      tableList: {
        list: [],
      },
      searchForm: {},// 查询参数
      pageNum: 1,
      pageSize: 15,

      bindTime: null,//时间
      colorList: [],//车牌颜色下拉框
      typeList: [],//车辆类型下拉框
      // 日历快捷
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },

      // 修改弹窗
      dialogFormVisible: false,

      formLabelWidth: '95px',
      // 修改表单参数
      form: {},
      //表单验证规则
      rules: {
        plateNumber: [{ required: true, message: '请输入车牌号', trigger: 'blur' }],
        numberPlateColorCode: [{ required: true, message: '请选择车牌颜色', trigger: 'change' }],
        vehicleTypeCode: [{ required: true, message: '请选择车辆类型', trigger: 'change' }],
      },
    };

  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    //获取分页器组件
    // this.Pager = this.$refs.tableRef.$children[1]
    this.queryTableList()
    this.getColorDict()
    this.getTypeDict()
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
  //方法集合
  methods: {
    //获取车辆信息列表
    queryTableList () {
      this.searchForm['pageNum'] = this.pageNum
      this.searchForm['pageSize'] = this.pageSize
      this.$cuCarInfo.queryCarBindList(this.searchForm).then(res => {
        res.resultEntity.list.sort((a, b) => new Date(a.bindTime).getTime() - new Date(b.bindTime).getTime())
        res.resultEntity.list.forEach(item => {
          if (item.phoneNumber) {
            item.phoneNumber = $commonsJs.phoneNumberDesensitizate(item.phoneNumber)
          }
        })
        this.tableList.list = res.resultEntity.list
        this.paginationConfig.total = res.resultEntity.total
      })
    },
    //分页
    getList (change) {
      this.pageSize = change.pageSize
      this.pageNum = change.pageNum
      this.queryTableList()
    },
    //查询
    handleClickSearch () {
      this.listMap.pageNum = 1
      this.pageNum = 1
      if (this.bindTime) {
        this.searchForm.startTime = this.bindTime[0]
        this.searchForm.endTime = this.bindTime[1]
      }
      else {
        this.searchForm.startTime = ''
        this.searchForm.endTime = ''
      }
      this.queryTableList()
    },
    // 重置
    resetForm () {
      this.searchForm = {}
      this.bindTime = []
      this.pageNum = 1
      this.listMap.pageNum = 1
      this.queryTableList()
    },
    //修改弹窗
    handleClickUpdate (row) {
      this.form = this.$deepClone(row)
      this.dialogFormVisible = true
    },
    //修改表单弹出框提交事件
    formSubmit () {
      this.$cuCarInfo.updateCarBind(this.form).then(() => {
        this.$message({
          showClose: true,
          message: '修改成功',
          type: 'success'
        });
        this.dialogFormVisible = false
        this.queryTableList()
      })
    },
    //获取车牌颜色
    getColorDict () {
      let info = {
        columnName: ['value', 'name'],
        tableName: 'tb_dict',
        whereStr: [
          {
            colName: 'parent_id',
            value: '204E657017184ADFAE6E39629B38DDC2',
          },
        ],
      }
      this.$queryDict.queryDict(info).then((response) => {
        let data = response.resultEntity
        this.colorList = data
      })
    },
    //获取车牌类型下拉框
    getTypeDict () {
      let info = {
        columnName: ['value', 'name'],
        tableName: 'tb_dict',
        whereStr: [
          {
            colName: 'parent_id',
            value: '3565E0605D304BA68F711A2D46B3661D',
          },
        ],
      }
      this.$queryDict.queryDict(info).then((response) => {
        let data = response.resultEntity
        this.typeList = data
      })
    },
  },
}
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
$topSelectHeight: 185px;

// 最外层div
.mainBody {
  height: 100%;
  // 顶部查询条件
  .topSelectTwoLine {
    width: 100%;
    height: $topSelectHeight;
    @include background('blockBg');
    padding-top: 24.3px;
    padding-left: 21px;
    .el-date-editor {
      border: none;
    }
    .topSecondLine {
      margin-top: 19.7px;
    }
    .topButton {
      display: flex;
      padding-left: 91px;
      margin-top: 17px;
    }
  }
  .tableRow {
    width: 100%;
    @include background('blockBg');
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      margin-top: 15px;
      .tableTitleButton {
        float: right;
      }
    }
    .tableContent {
      margin-top: 10px;
    }
  }
}
</style>