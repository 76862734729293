/*
 * @Description: 公共方法组件
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @this: 2021-12-06 16:10:14
 * @LastEditors: zhoucheng
 */
import moment from "moment" // 使用时间间隔引入

export default {
  /**
   * @name: 手机号脱敏
   * @description: 将手机号中间四位变成*
   * @param {type} String
   * @eq 138****5621
   * @return {type} String
   */
  phoneNumberDesensitizate (phoneNumber) {
    const phoneNumberData = phoneNumber.slice(0, 3) + "****" + phoneNumber.slice(phoneNumber.length - 4);
    // const phoneNumberData = phoneNumber.substring(0, 3) + "****" + phoneNumber.substring(phoneNumber.length - 4);
    // const phoneNumberData = phoneNumber.replace(/(\d{3})\d*(\d{4})/, "$1****$2"); 
    return phoneNumberData
  },
  /**
   * @name: 邮箱脱敏
   * @description: 将邮箱中间四位变成*
   * @param {type} String
   * @eq 738560****@qq.com
   * @return {type} String
   */
  emailDesensitizate (email) {
    let str = email.split("@");
    const emailData = str[0].substr(0, str[0].length - 3) + "*****" + str[1].substr(2);
    return emailData
  },
  /**
 * @name: 姓名脱敏
 * @description: 将姓变成*
 * @param {type} String
 * @eq *成成
 * @return {type} String
 */
  nameDesensitizate (name) {
    const nameData = "*" + name.substr(1);
    return nameData
  },
  /**
  * @name: 身份证脱敏
  * @description: 将身份证中间四位变成*
  * @param {type} String
  * @eq  5123****5645
  * @return {type} String
  */
  idcardDesensitizate (idcard) {
    const idcardData = idcard.replace(/^(.{4})(?:\w+)(.{4})$/, "$1****$2");
    // const idcardData = idcard.slice(0, 4) + "****" + idcard.slice(idcard.length - 4);
    return idcardData
  },
  /**
   * @name: 将时间分钟格式化成小时、天 
   * @description: 只控制到30天
   * @param {type} number、String 
   * @eq 3000分钟
   * @return {type} String
   */
  minuteTimeFormat (time) {
    if (time === undefined || time === 0) {
      time = 0 + '分钟'
    }
    else if (time > 0 && time < 60) {
      time = time + '分钟'
    }
    else if (time >= 60 && time < 1440) {
      if (time % 60 === 0) {
        time = parseInt(time / 60) + '小时'
      } else {
        time = parseInt(time / 60) + '小时' + time % 60 + '分钟'
      }
    }
    else if (time >= 1440 && time <= 43200) {
      if (time % 1440 === 0) {
        time = parseInt(time / 1440) + '天'
      }
      else if (parseInt((time % 1440) % 60) === 0) {
        time = parseInt(time / 1440) + '天' + parseInt((time % 1440) / 60) + '小时'
      }
      else if (parseInt((time % 1440) % 60) !== 0) {
        if (parseInt((time % 1440) / 60) === 0) {
          time = parseInt(time / 1440) + '天' + (time % 1440) % 60 + '分钟'
        }
        else if (parseInt((time % 1440) / 60) !== 0) {
          time = parseInt(time / 1440) + '天' + parseInt((time % 1440) / 60) + '小时' + (time % 1440) % 60 + '分钟'
        }
      }
    }
    return time
  },
  /**
   * @name: 时间间隔
   * @description: moment实现时间间隔
   * @param {type} {currentTime:string,当前时间, pastTime:string:过去的时间}
   * @eq 格式化之后的时间 2020-01-01 00:00:00
   * @return {type} {*}
   */
  timeInterval (pastTime, currentTime) {
    let timeIntervalBack;
    let m1 = moment(pastTime)
    let m2 = moment(currentTime)
    let years = moment.duration(m2 - m1)._data.years
    let months = moment.duration(m2 - m1)._data.months
    let days = moment.duration(m2 - m1)._data.days
    let hours = moment.duration(m2 - m1)._data.hours
    let minutes = moment.duration(m2 - m1)._data.minutes
    let seconds = moment.duration(m2 - m1)._data.seconds
    years === 0 ? years = '' : years += '年'
    months === 0 ? months = '' : months += '月'
    days === 0 ? days = '' : days += '天'
    hours === 0 ? hours = '' : hours += '小时'
    minutes === 0 ? minutes = '' : minutes += '分钟'
    seconds === 0 ? seconds = '' : seconds += '秒'
    timeIntervalBack = years + months + days + hours + minutes + seconds
    return timeIntervalBack
  }
}